@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

:root {
  --main-color: #163970;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F8FA !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-container {
  position: absolute;
  bottom: 0;
  text-align: center;
}

.footer-container h2 {
  font-size: 0.8rem;
  padding: 0 !important;
  margin: 0 !important;
}

.main-page-logo {
  width: 75%;
  height: auto;
  padding-top: 25px;
  padding-bottom: 10px;
  max-width: 400px;
}

.qa-page-logo {
  width: 50%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 150px;
}

.main-page-title-wrapper {
  background-color: var(--main-color);
  width: 100%;
  padding: 10px;
}

.main-page-title {
  color: #FFF;
  font-weight: 600;
  font-size: 1.5rem;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.end-page-title-wrapper {
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}

.end-page-title {
  color: #000;
  font-weight: 600;
  font-size: 1.2rem;
}

.end-page-subtitle {
  color: #000;
  font-weight: 500;
  font-size: 1rem;
}

.main-page-subtitle {
  color: #FFF;
  text-align: center;
  font-size: 1rem;
  font-weight: 700 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.qa-page-title {
  color: #000;
  font-weight: 600;
  text-align: left;
  font-size: 1.6rem;
}

.qa-page-subtitle {
  color: #000;
  font-weight: 500;
  text-align: left;
  font-size: 1.2rem;
}

.qa-page-answer-title {
  color: var(--main-color);
  font-weight: 700;
  text-align: left;
  font-size: 2rem;
}

.qa-page-answer-message {
  color: var(--main-color);
  font-weight: 500;
  text-align: left;
  font-size: 1.4rem;
  background-color: #eeeeee;
  padding: 2%;
  border-radius: 2rem;
}

.social-links {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: solid 0.15rem #DDE5EB;
  border-bottom: solid 0.15rem #DDE5EB;
}

.social-links svg {
  font-size: 4rem;
  color: var(--main-color);
  padding: 5px;
  transition: 300ms;
}

.social-links svg:hover {
  transform: scale(1.5);
  color: #000;
}

.social-links-end {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: solid 0.15rem #DDE5EB;
  border-bottom: solid 0.15rem #DDE5EB;
  background-color: var(--main-color);
  width: 100%;
  display: flex;
  justify-content: center;
}

.social-links-end svg {
  font-size: 4rem;
  color: #FFF;
  padding: 5px;
  transition: 300ms;
}

.social-links-end svg:hover {
  transform: scale(1.5);
  color: #000;
}

.buttons-main {
  background-color: var(--main-color) !important;
  box-shadow: none !important;
  font-size: 1rem !important;
  width: 100%;
  border-radius: 10px !important;
  text-transform: none !important;
  color: #FFF;
  font-weight: 600 !important;
  padding: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms !important;
}

.buttons-main:hover {
  background-color: #000 !important;
  transform: translateY(-10px);
  box-shadow: 0px 5px 5px #0002 !important;
}

.buttons-main svg {
  font-size: 2.2rem;
}

.buttons-main-wpp {
  background-color: #6CC526 !important;
  box-shadow: none !important;
  font-size: 1rem !important;
  width: 100%;
  border-radius: 10px !important;
  text-transform: none !important;
  color: #FFF;
  font-weight: 600 !important;
  padding: 15px !important;
  justify-content: center;
  align-items: center;
  transition: 300ms !important;
}

.buttons-main-wpp:hover {
  background-color: #80ff25 !important;
  transform: translateY(-10px);
  color: #FFF !important;
  box-shadow: 0px 5px 5px #0002 !important;
}

.buttons-main-wpp svg {
  font-size: 2.2rem;
}

.buttons-title {
  font-weight: 900;
  color: var(--main-color);
  font-size: 2rem;
  line-height: 115%;
}

.buttons-subtitle {
  padding-top: 1.5rem;
  color: #1e1e1e;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 115%;
}

.timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  transition: 300ms;
}

.timeline-ball {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: #e1e1e1 solid 0.5rem;
  background-color: #e1e1e1;
  margin-right: 10px;
  cursor: pointer;
  transition: 300ms;
}

.timeline-ball.filled {
  background-color: var(--main-color);
  transform: scale(1.5) !important;
  border: none;
}

.timeline-ball.previous {
  border: var(--main-color) solid 0.2rem;
  background-color: #FFF;
  transform: scale(1) !important;
}

.timeline-ball:hover {
  transform: scale(1.2);
}

.qa-header-bg {
  color: var(--main-color);
  padding: 0 !important;
  margin: 0;
  transition: 300ms;
}

.qa-header-bg svg {
  width: 100%;
}

.qa-header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qa-register-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.qa-register-logo svg {
  fill: #000;
  color: #FFF;
  width: 35svw;
  height: 15svw;
  padding: 5px;
}

.qa-header-logo svg {
  fill: #000;
  color: var(--main-color);
  width: 15svw;
  height: 15svw;
  background-color: #F5F8FA;
  border-radius: 50%;
  padding: 5px;
}

.qa-page-title-wrapper {
  width: 100%;
  height: 25svh;
  padding: 0 !important;
  margin: 0 !important;
  position: relative !important;
  display: inline !important;
  transition: 300ms;
}

.qa-page-title-wrapper img {
  padding: 0 !important;
  margin: 0 !important;
  display: inline !important;
}

.qa-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.qa-button {
  background-color: var(--main-color) !important;
  border-radius: 1rem !important;
  font-size: 1.2rem !important;
  padding: 10px 20px !important;
  width: 100%;
}

.qa-button-l {
  font-size: 1.8rem;
  color: var(--main-color) !important;
  padding: 0.5rem 0.7rem;
  background-color: #FFF;
  border: var(--main-color) 0.15rem solid;
  border-radius: 15px;
  transition: 300ms;
  font-weight: 600;
  cursor: pointer;
}

.qa-button-r {
  font-size: 1.8rem;
  color: #FFF !important;
  padding: 0.5rem 2rem;
  background-color: var(--main-color);
  border: var(--main-color) 0.15rem solid;
  border-radius: 15px;
  transition: 300ms;
  cursor: pointer;
}

.qa-button-next {
  font-size: 2rem;
  color: #FFF;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: var(--main-color);
  border: var(--main-color) 0.15rem solid;
  border-radius: 15px;
}

.qa-button-next:hover {
  background-color: #FFF;
  color: var(--main-color);
  transform: scale(1.2);
}

.qa-register-wrapper label {
  font-weight: 700;
  color: #000;
}

.qa-input-register {
  border: 0.1rem solid;
  border-color: var(--main-color);
  border-radius: 0.5rem;
}

.qa-textarea {
  width: 100% !important;
  border-radius: 15px;
}

.question {
  text-align: left !important;
  width: 90%;
  padding-top: 3rem;
  font-size: 1.6rem;
  color: #000;
  font-weight: 700;
  line-height: 28px;
}

.timeline {
  position: relative;
}

.qa-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qa-video-wrapper {
  width: 100%;
  height: 100%;
}

.qa-option-wrapper {
  display: flex;
}

.qa-option-wrapper input {
  margin-right: 2rem !important;
}

.custom-radio {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  transition: 300ms !important;
}

.custom-radio input[type="radio"] {
  display: none;
  transition: 300ms !important;
}

.custom-radio .circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #E1E1E1;
  background-color: #E1E1E1;
  margin-right: 10px;
  transition: 300ms !important;
}

.custom-radio input[type="radio"]:checked+label .circle {
  border-color: var(--main-color);
  background-color: var(--main-color);
  transition: 300ms !important;
}

.custom-radio .qa-option-label {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms !important;
}

.custom-radio .circle::after {
  content: attr(data-letter);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0px;
  height: 0px;
  padding: 8px;
  color: var(--main-color);
  font-weight: bold;
  font-size: 14px;
  transition: 300ms !important;
}

.custom-radio input[type="radio"]:checked+label .circle::after {
  color: #FFF;
  transition: 300ms !important;
}

.message {
  border: var(--main-color) 0.1rem solid;
  border-radius: 1rem;
  text-align: center;
  padding: 0.2rem 0.7rem;
  margin: 0.5rem 2rem;
}

.message p {
  margin: 0;
  font-weight: 600;
  color: var(--main-color);
}

@media screen and (min-width: 764px) {
  .timeline {}

  .qa-container {
    background-color: #F5F8FA;
    z-index: 1;
    border-radius: 2rem;
    margin: 0rem 10rem !important;
    max-width: 65% !important;
    padding: 3svw 3svw !important;
    filter: drop-shadow(0px 5px 5px #1e1e1e50);
  }

  .qa-questions-wrapper {
    padding: 25px;
    display: flex;
    justify-content: center;
  }

  .buttons-wrapper {
    background-color: #F5F8FA;
    z-index: 1;
    border-radius: 2rem;
    margin: 0rem 10rem !important;
    max-width: 55% !important;
    filter: drop-shadow(0px 5px 5px #1e1e1e50);
    padding: 5svw 5svw !important;
  }

}

@media screen and (max-width: 764px) {
  .footer-container {
    position: relative;
  }

  .qa-page-title-wrapper {
    width: 100%;
    height: auto;
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
    display: inline !important;
  }

  .qa-header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 300ms;
  }

  .qa-header-logo svg {
    fill: #000;
    color: var(--main-color);
    width: 25svw;
    height: 25svw;
    background-color: #F5F8FA;
    border-radius: 50%;
    padding: 5px;
  }

  .qa-register-logo svg {
    fill: #000;
    color: #FFF;
    width: 100svw;
    height: 15svw;
    padding: 5px;
  }

  .buttons-wrapper {
    height: 70svh;
  }
}